import React, { FC } from 'react';
import { graphql } from 'gatsby';

import NewsletterButtonBanner from 'components/common/NewsletterButtonBanner';
import Layout from 'components/Layout';
import FooterPromo from 'components/FooterPromo';
import BodyRenderer from 'templates/HomePage/BodyRenderer';
import { THomePageComponentProps } from './models';
import 'styles/main.scss';

const HomePage: FC<THomePageComponentProps> = ({
  pageContext: { xOrigin, relatedProductsLinks },
  data: {
    languageSelector,
    siteSettings,
    homepageSettings,
    menu,
    footerNavigation,
    mobileAppPromoBanner,
    relatedArticles,
    relatedProducts,
    promoProduct,
    allHomepageBlocks,
    introProducts,
  },
}) => {
  const { link, ...seo } = homepageSettings;
  const sortedRelatedProducts = relatedProductsLinks
    .map((linkRelatedProduct) => relatedProducts.nodes.find((p) => p.link === linkRelatedProduct))
    .filter((p): p is ProductTypes.IProduct => !!p);

  return (
    <Layout
      headerTransparent
      seo={seo}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      languageSelector={languageSelector}
      url={link}
    >
      {allHomepageBlocks.nodes.map((block) => (
        <BodyRenderer
          key={block.blockType}
          block={{ ...block, xOrigin }}
          salsifyLang={siteSettings.salsifyLang}
          relatedArticles={relatedArticles.nodes}
          relatedProducts={sortedRelatedProducts}
          promoProduct={promoProduct}
          introProducts={introProducts}
        />
      ))}
      {homepageSettings.didYouKnow?.didYouKnowBG ? (
        <FooterPromo didYouKnow={homepageSettings.didYouKnow} mask />
      ) : null}
      {Number(siteSettings.newsletterSignUp) ? (
        <NewsletterButtonBanner newsletterBanner={homepageSettings.newsletterBanner} isFixed />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query HomePageQuery(
    $relatedArticlesLinks: [String]
    $relatedProductsLinks: [String]
    $productsLimit: Int
    $articlesLimit: Int
    $promoProductLink: String
    $lang: String
  ) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
      salsifyLang
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
      link
      seoMetaDescription
      seoMetaKeywords
      seoMetaTitle
      seoCanonicalUrl
      seoImage
      seoExternalHreflangs {
        key
        value
      }
      didYouKnow {
        labelText
        descriptionText
        buttonText
        ariaLabel
        imageAlt
        sectionLandmark
        didYouKnowBG {
          fluid {
            srcSet
            base64
          }
          fallbackUrl
        }
        buttonURL {
          url
          icon
        }
      }
    }
    introProducts: homepageBlocks(blockType: { eq: "Products categories" }, lang: { eq: $lang }) {
      products {
        imageAlt
        title
        subTitle
        image {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        link {
          url
        }
      }
    }
    allHomepageBlocks(filter: { lang: { eq: $lang } }) {
      nodes {
        blockType
        againButtonText
        boldTitle
        buttonText
        buttonURL {
          url
        }
        ariaLabel
        categoryQuestionLabel
        descriptionText
        id
        image {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        imageAlt
        labelText
        nextButtonText
        number
        panelImage {
          fallbackUrl
          fluid {
            base64
            srcSet
          }
        }
        postItNoteAlt
        postItNoteImage {
          fallbackUrl
          fluid {
            base64
            srcSet
          }
        }
        panelType
        panelVideo
        prevButtonText
        products {
          id
          image {
            fallbackUrl
            fluid {
              base64
              srcSet
            }
          }
          imageAlt
          link {
            url
          }
          name
          structure
          subTitle
          title
          visible
        }
        questions {
          id
          key
          label
          type
          options {
            id
            label {
              text
              image {
                fallbackUrl
                fluid {
                  base64
                  srcSet
                }
              }
            }
            value
            link
            tags {
              id
              label {
                text
                title
              }
              value
            }
          }
        }
        regularTitle
        searchResultsPage
        noResultsFoundLabel
        resultsLoadingLabel
        tagQuestionLabel
        titleBoldText
        titleRegularText
        titleText
        sectionLandmark
        disclaimer
        customImageToggle
        productImage {
          fallbackUrl
          fluid {
            base64
            srcSet
          }
        }
      }
    }
    relatedArticles: allUmbracoArticle(
      limit: $articlesLimit
      filter: { link: { in: $relatedArticlesLinks } }
    ) {
      nodes {
        title
        link
        intro
        label
        image {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
      }
    }
    relatedProducts: allUmbracoProduct(
      limit: $productsLimit
      filter: { link: { in: $relatedProductsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        shortNameRegular
        shortNameBold
        recognisableFeature
        name
        link
        id
        productColor
        localHeroImage {
          childImageSharp {
            fluid(maxWidth: 190, quality: 65) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        cardImage {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
      }
    }
    promoProduct: umbracoProduct(link: { eq: $promoProductLink }) {
      name
      localHeroImage {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export default HomePage;
